import axiosClient from "./axiosClient";

const goodsApi = {
    searchGoods(params) {
        const url = '/goods';
        return axiosClient.get(url, { params: params });
    },
    getGoodsDetails(params) {
        const url = '/goods/details';
        return axiosClient.get(url, { params: params });
    },
    searchGoodsSub(params) {
        const url = '/goods_sub';
        return axiosClient.get(url, { params: params });
    },
    addGoodsFavorite(params) {
        const url = '/goods/favorite';
        return axiosClient.post(url, params);
    }
    ,
    updateGoodsFavorite(params) {
        const url = '/goods/favorite';
        return axiosClient.post(url, params);
    }
    ,
    getGoodsPirce(params) {
        const url = '/goods/price';
        return axiosClient.post(url, params);
    }
    ,
    morienShopSearch(params) {
        const url = '/morien-shop/goods';
        return axiosClient.get(url, { params: params });
    }
    ,
    bargainGoodsSearch(params) {
        const url = '/bargain-goods';
        return axiosClient.get(url, { params: params });
    }
}

export default goodsApi;
