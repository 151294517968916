import { faMinus, faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addGoods } from "features/Cart/cartSlice";
import {
  BtnClose, BtnQuantity, ButtonSelect, Dialog
  , DialogContent, DialogTitle, Line, MLabel, Overlay, Quantity
  , TextContent
  , TextTile, WrapperQuantity
} from './styles'
import SelectColor from "./SelectColor";
import goodsApi from "api/goodsApi";
import Common from "Common";

const { showPrice } = Common.localStorageGetUserInfo();

const DialogAddGoods = ({ goods, handleClose }) => {
  const { selectedList } = useSelector((state) => state.cart);
  const [quantity, setQuantity] = useState(1);
  const dispatch = useDispatch();
  const showSelectColor = !!goods?.GoodsName ? goods.GoodsName.trim().endsWith("調色") : false;
  const [colorBook, setColorBook] = useState({
    selectedValue: {},
    options: []
  });
  const [colorNo, setColorNo] = useState({
    selectedValue: {},
    options: []
  });
  const handleSelectGoods = async () => {
    try {
      //色見本帳選択
      let colorInfo = {};
      if (!showSelectColor) {
        colorInfo = {};
      } else {
        const selectedColorBook = colorBook.selectedValue;
        const selectedColorNo = colorNo.selectedValue;
        colorInfo = {
          ColorBookID: selectedColorBook.ColorBookID,
          ColorNoID: selectedColorNo.ColorNoID,
          ColorLevelID: selectedColorNo.ColorLevelID,
          ColorNoName: selectedColorNo.ColorNoName
        }
      }
      // Get Goods Price
      if (!!showPrice) {
        let goodsNum = goods.Quantity;

        const iInCart = !!selectedList?.lenght ? selectedList.find((item) => { return item.goods.GoodsID === goods.GoodsID && item.goods.GoodsSubID === goods.GoodsSubID }) : null;
        if (!!iInCart && iInCart.Quantity > 0) {
          goodsNum += iInCart.quantity;
        }
        const params = {
          goodsID: goods.GoodsID,
          goodsSubID: goods.GoodsSubID || 1,
          goodsNum: goodsNum,
        }
        const { data } = await goodsApi.getGoodsPirce(params);
        goods.Price = data?.price || "未定";

      } else {
        goods.Price = "未定";
      }
      //select goods to cart
      const selectedGoods = {
        goods: { ...goods, GoodsSubID: goods.GoodsSubID || 0, GoodsName: `${goods.GoodsName}`.trim(), GoodsKana: goods.GoodsKana || goods.GoodsKanaReal || '', ...colorInfo },
        quantity: quantity
      }

      dispatch(addGoods(selectedGoods));
      handleDialogClose();
    } catch (error) {
      console.log(error);
    }
  }

  const handleDialogClose = () => {
    setQuantity(1);
    handleClose();
  }


  return <>
    {goods?.GoodsID && (<>
      <Dialog>
        <DialogTitle>
          <TextTile>数量選択</TextTile>
          <BtnClose onClick={() => { handleDialogClose(); }}><FontAwesomeIcon icon={faXmark} /></BtnClose>
        </DialogTitle>
        <DialogContent>
          <TextContent>
            <Line>
              <MLabel>商品：</MLabel>{goods.GoodsName || '―'}
            </Line>
            <Line>
              <MLabel>サイズ：</MLabel>{goods.GoodsSize || '-'}
            </Line>
            {!!showSelectColor && (<SelectColor colorBook={colorBook} setColorBook={setColorBook} colorNo={colorNo} setColorNo={setColorNo} />)}
          </TextContent>
          <WrapperQuantity>
            <BtnQuantity onClick={() => { if (quantity > 1) setQuantity(prevValue => (prevValue - 1)) }}><FontAwesomeIcon icon={faMinus} /></BtnQuantity>
            <Quantity>{quantity}</Quantity>
            <BtnQuantity onClick={() => setQuantity(prevValue => (prevValue + 1))} ><FontAwesomeIcon icon={faPlus} /></BtnQuantity>
          </WrapperQuantity>
          <ButtonSelect onClick={() => { handleSelectGoods(); }} >選択</ButtonSelect>
        </DialogContent>
      </Dialog>
      <Overlay /></>)}
  </>;
};

export default DialogAddGoods
