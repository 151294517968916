import styled from 'styled-components';


export const CheckBox = styled.input.attrs({ type: 'checkbox' })`
    display: none;

    :checked ~ #idOverlay {
        display: block;
        opacity: 1;
    }

    :checked ~ #idWrapper {
        transform : translateX(0);
        opacity: 1;
    }
`
export const OverLay = styled.label.attrs({ id: 'idOverlay' })`
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    animation: fadeIn linear 0.2s;
    z-index: 95;
`;

export const Wrapper = styled.div.attrs({ id: 'idWrapper' })`
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 0;
    right: 0;
    bottom: 0;    
    background-color: white;
    width: 80%;
    transform : translateX(110%);
    transition: transform linear 0.2s, opacity linear 0.2s;
    opacity: 0;
    z-index: 96;
`
export const Header = styled.div`    
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(50,205,50,0.4);
`
export const SiteInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: between;
    padding:  6px 0 0 5px;
`;


export const SiteName = styled.label`
    flex-grow: 1;
    font-size: 1.1rem; 
    font-weight: bold;   
`;
export const SiteAddr = styled.label`
    flex-grow: 1;
    font-size: 0.7rem;
`;

export const BtnClose = styled.label`
    padding: 10px 15px;
    cursor: pointer;
    border: none;
    outline: none;
    background: none;
    font-size: 1.25rem;
    font-weight: bold;
    :active {
        background-color: red;
    }
`

export const SelectedList = styled.div`
    overflow-y:scroll;
    overflow-x: hidden;
`
export const ButtonDiv = styled.div`
    display: flex;
    justify-content: center;
    
    .ButtonConfirm{
        width: 70%;
        max-width: 250px;
        margin: 20px 0;
        padding: 8px;
        font-size: 1rem;
        font-weight: bold;
        outline: none;
        border:1px solid transparent;
        border-color: var(--morienGreen);
        border-radius: 8px;
        background-color: var(--morienGreen);
        color: white;
        cursor: pointer;
        :active{
            background-color: #32cd32;            
        }

        text-align: center;
        text-decoration: none;
    }
`

export const ButtonConfirm = styled.button`
    width: 70%;
    margin: 20px 0;
    padding: 8px;
    font-size: 1rem;
    font-weight: bold;
    outline: none;
    border:1px solid transparent;
    border-color: var(--morienGreen);
    border-radius: 8px;
    background-color: var(--morienGreen);
    color: var(--white);
    cursor: pointer;
    :active{
        background-color: #32cd32;            
    }
`

